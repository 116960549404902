<template>
    <div></div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            deferredPrompt: null,
        };
    },
    computed: {
        ...mapGetters(["currentUser"]),
    },
    created() {
        if (this.currentUser) {
            // console.log(this.currentUser);
            // if( this.currentUser.id == 3852 ||
            //     this.currentUser.parent_id == 3627 ||
            //     this.currentUser.parent_id == 3852){
            //   this.showMsgDebtor();
            // } else if(this.currentUser.id == 4342){
            //   this.showMsgDebtorAgent();//lien he chung toi 
            // }else if(this.currentUser.id == 5102){
            // this.showMsgDebtor();//lien he dai ly
            //Thong bao + thang
            // this.showMsgErrorPlusMonth()
            // this.showMsgErrorKQXS()

            if (this.currentUser.is_lock_money === true && this.currentUser.role[0] != "user") {
                this.showMsgDebtor();
            } else if (this.currentUser.expired_in - 1 <= 3) {
                this.showMsgExpried(this.currentUser.expired_in - 1);
            } else {
                this.goToFirstPage();
            }
        } else {
            this.goToFirstPage();
        }
    },
    methods: {
        goToFirstPage() {
            let firstPage = "betSms";
            if (this.currentUser && this.currentUser.role) {
                firstPage = this.currentUser.role[0] == "agent" ? "users" : "betSms";
                // firstPage = this.currentUser.role[0] == "agent" ? "users" : "guide";
            }
            this.$router.replace({ name: firstPage });
        },
        showMsgErrorKQXS(){
            const h = this.$createElement;
            const titleVNode = h("div", {
                domProps: { innerHTML: "Kết quả MT đài Komtum 16/02 bị lộn !" },
            });
            const messageVNode = h("div", { class: ["foobar px-3"] }, [
                h("p", { class: ["text-left"] }, [
                    "Kết quả MT đài Komtum Chủ Nhật ngày 16/02 bị lộn, những khách nào có cược số 42 và 12 MT đài Komtum sẽ bị ảnh hưởng. Quý khách vui lòng kiểm tra lại. Xin lỗi vì sự bất tiện này!",
                ]),
            ]);
            this.$bvModal
                .msgBoxOk([messageVNode], {
                    title: [titleVNode],
                    hideHeaderClose: false,
                    noCloseOnBackdrop: false,
                })
                .then(() => {
                    if (this.currentUser.is_lock_money === true && this.currentUser.role[0] != "user") {
                        this.showMsgDebtor();
                    } else if (this.currentUser.expired_in - 1 <= 3) {
                        this.showMsgExpried(this.currentUser.expired_in - 1);
                    } else {
                        this.goToFirstPage();
                    }
                });
        },
        showMsgErrorPlusMonth() {
            const h = this.$createElement;
            const titleVNode = h("div", {
                domProps: { innerHTML: "Sự cố với máy chủ !" },
            });
            const messageVNode = h("div", { class: ["foobar px-3"] }, [
                h("p", { class: ["text-left"] }, [
                    "Do có sự cố với máy chủ, kỹ thuật đã cố gắng nhưng dữ liệu của quý khách từ ngày 20/09/2024-30/09/2024 bị mất. Để khắc phục hậu quả, cty tặng mỗi khách hàng 1 tháng sử dụng miễn phí. Mong quý khách hàng thông cảm, cảm ơn quý khách đã đồng hành cùng công ty!",
                ]),
            ]);
            this.$bvModal
                .msgBoxOk([messageVNode], {
                    title: [titleVNode],
                    hideHeaderClose: true,
                    noCloseOnBackdrop: true,
                })
                .then(() => {
                    if (this.currentUser.is_lock_money === true && this.currentUser.role[0] != "user") {
                        this.showMsgDebtor();
                    } else if (this.currentUser.expired_in - 1 <= 3) {
                        this.showMsgExpried(this.currentUser.expired_in - 1);
                    } else {
                        this.goToFirstPage();
                    }
                });
        },
        showMsgDebtorAgent() {
            const h = this.$createElement;
            const titleVNode = h("div", {
                domProps: { innerHTML: "Tài khoản bị khoá !" },
            });
            const messageVNode = h("div", { class: ["foobar px-3"] }, [
                h("p", { class: ["text-left"] }, ["Tài khoản bị khoá"]),
                h("p", { class: ["text-left"] }, [
                    "Xin lỗi chúng tôi ko thể liên lạc với bạn, hãy nhắn tin cho hỗ trợ kỹ thuật trong mục cài đặt hoặc Telegram đến https://t.me/samsam052",
                ]),
            ]);
            this.$bvModal
                .msgBoxOk([messageVNode], {
                    title: [titleVNode],
                    hideHeaderClose: true,
                    noCloseOnBackdrop: true,
                    hideFooter: true,
                })
                .then(() => {
                    this.showMsgDebtorAgent();
                });
        },
        showMsgDebtor() {
            const h = this.$createElement;
            const titleVNode = h("div", {
                domProps: { innerHTML: "Tài khoản bị khoá !" },
            });
            const messageVNode = h(
                "div", {
                domProps: {
                    innerHTML: this.currentUser.msg
                },
                class: ["foobar px-3"]
            }
            );
            this.$bvModal
                .msgBoxOk([messageVNode, h('div', { class: ['text-right'] }, [
                    h('button', {
                        class: ['btn', 'btn-primary'], // Lớp CSS cho nút
                        on: { click: this.logout } // Thêm sự kiện click để gọi phương thức logout
                    }, ["Đăng xuất"]),
                ])], {
                    title: [titleVNode],
                    hideHeaderClose: true,
                    noCloseOnBackdrop: true,
                    hideFooter: true,
                })
                .then(() => {
                    this.showMsgDebtor();
                });
        },
        logout() {
            let vm = this;
            vm.$http.post("//" + process.env.VUE_APP_API + "/logout").then(res => {
                if (res.data.code == 0) {
                    vm.$store.dispatch("logout");
                    window.location.reload();
                }
            });
        },
        showMsgContactTele() {
            const h = this.$createElement;
            const titleVNode = h("div", {
                domProps: { innerHTML: "Liên hệ với công ty !" },
            });
            const messageVNode = h("div", { class: ["foobar px-3"] }, [
                h("p", { class: ["text-left"] }, [
                    "Xin lỗi đã làm phiền, công ty bị mất số liên lạc của bạn, hãy liên lạc Telegram samsam052",
                ]),
                h("a", { href: ["https://t.me/samsam052"] }, [
                    "https://t.me/samsam052",
                ]),
                h("p", { class: ["text-left"] }, [
                    "Làm 1 trong 2 cách sau:",
                ]),
                h("p", { class: ["text-left"] }, [
                    "1. Gõ samsam052 vào ô tìm kiếm trong Telegram, chọn Sam Sam NEW và nhắn tin",
                ]),
                h("p", { class: ["text-left"] }, [
                    "2. Đăng nhập trang con (trang người dùng) lienquan, ấn vào mục Chat Telegram kỹ thuật",
                ]),
                h("p", { class: ["text-left"] }, [
                    "Telegram samsam cũ đã bị mất, ko liên lạc vào số cũ. Xin cảm ơn!",
                ])
            ]);
            this.$bvModal
                .msgBoxOk([messageVNode], {
                    title: [titleVNode],
                    hideHeaderClose: true,
                    noCloseOnBackdrop: true,
                    hideFooter: true,
                })
                .then(() => {
                    this.goToFirstPage();
                });
        },
        showMsgExpried(days) {
            const h = this.$createElement;
            const titleVNode = h("div", {
                domProps: { innerHTML: "Cảnh báo hết hạn tài khoản !" },
            });
            const messageVNode = h("div", { class: ["foobar px-3"] }, [
                h("p", { class: ["text-left"] }, [
                    "Quý khách còn hạn sử dụng " + days + " ngày",
                ]),
                h("p", { class: ["text-left"] }, [
                    "Vui lòng liên hệ đại lý để gia hạn. Xin cảm ơn!",
                ]),
            ]);
            this.$bvModal
                .msgBoxOk([messageVNode], {
                    title: [titleVNode],
                    hideHeaderClose: true,
                    noCloseOnBackdrop: true,
                })
                .then(() => {
                    this.goToFirstPage();
                });
        },
    },
};
</script>
